.App {
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
}

@font-face {
    font-family: "New Athletic";
    src: url("./fonts/New\ Athletic\ M54.ttf");
}

@font-face {
    font-family: "Sports Jersey";
    src: url("./fonts/sportsjersey.ttf");
}

* {
    box-sizing: border-box;
}

.message {
    margin: 0;
    margin-top: 1.5rem;
    font-size: medium;
    font-style: italic;
}

:root {
    --theme-background: rgb(44, 44, 44);
    --theme-contrast: rgb(239, 239, 239);
    --theme-onhover: rgb(205, 205, 205);
    --theme-favorited: rgb(255, 235, 135);
    --theme-bg: rgb(226, 226, 226);
}

body {
    background-color: var(--theme-bg);
}

nav {
    width: 100vw;
    position: sticky;
    top: 0;
    display: flex;
    background-color: var(--theme-background);
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.right-links > a {
    font-family: "Sports Jersey";
    color: var(--theme-contrast);
    padding: 0.5rem 1rem;
}

nav > div > a {
    text-decoration: none;
}

.title {
    margin: 0;
    padding: 0.5rem;
    font-family: "New Athletic";
    color: var(--theme-contrast);
    background-color: var(--theme-background);
    width: 100%;
}

/* TABLE */

.fight-table {
    width: calc(100vw - 6rem);
    display: flex;
    justify-content: center;
}

.upcoming-fights, .past-fights, .scheduled-fights {
    text-align: left;
    margin-inline: 3rem;
    min-width: max-content;
}

.table-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

div.upcoming-fights > div.table-header > h2 {
    margin-top: 5px;
}

.expand-icon, h2 {
    align-self: end;
    margin-bottom: 3px;
    padding: 7px 0;
    border-top: 0.5px solid black;
    border-bottom: 0.5px solid black;
    background-color: var(--theme-background);
    color: var(--theme-contrast);
}

.expand-icon {
    color: var(--theme-background)
}

h2 {
    padding-left: 10px;
    width: 100%;
    font-family: "Sports Jersey";
}

table {
    margin: 10px 0;
    border-radius: 4px;
    border-collapse: collapse;
}

thead {
    text-align: center;
}

td {
    padding: 0.5rem 2rem;
    border-top: 0.5px solid gray;
}

tbody > tr:first-child > td {
    border-top: 2px solid black
}

td:first-child, tbody > tr:first-child > td:first-child {
    border-left: none;
    border-top: none;
}

td:last-child {
    border-right: none;
    text-align: left;
}

tbody > tr:hover {
    background-color: var(--theme-onhover);
}

/* EXPAND ICON AND H2 HOVER */

h2:hover, h2:hover + .expand-icon, .expand-icon:hover {
    box-shadow: 0px 0px 10px 0px gray;
    cursor: pointer;

}

h2:hover + .expand-icon, .expand-icon:hover {
    color: var(--theme-contrast);
}

/* Filter form */

.filter-form {
    text-align: start;
    margin-inline: 3rem;
}

input {
    margin: 3px;
}

label {
    margin-inline: 5px;
}

label:first-child, label:last-child {
    margin-inline-start: 0px;
}

/* ADD HIGHLIGHT */

.fav {
    background-color: var(--theme-favorited);
}

.fav:hover {
    background-color: var(--theme-favorited);
}

/* TODO: MAKE RESPONSIVE VIEW FOR PHONE */

@media screen and (max-width: 641px) {
    /* body {
        background-color: blanchedalmond;
    } */
    td {
        padding: 7px 3px;
    }
    .upcoming-fights, .past-fights, .scheduled-fights {
        text-align: left;
        margin-inline: 1.5rem;
        min-width: max-content;
    }
    .filter-form {
        margin-inline: 1.5rem;
    }
    input {
        margin: 0px;
    }
    label {
        margin-inline: 2px;
    }
    .fight-table {
        width: calc(100vw - 3rem);
        display: flex;
        justify-content: center;
    }
    
}

@media screen and (min-width: 641px) and (max-width: 930px) {
    /* body {
        background-color: lightgray;
    } */
    td {
        padding: 0.5rem 1rem;
    }
    .upcoming-fights, .past-fights, .scheduled-fights {
        text-align: left;
        margin-inline: 1.5rem;
        min-width: max-content;
    }
    .filter-form {
        margin-inline: 1.5rem;
    }
    input {
        margin: 0px;
    }
    label {
        margin-inline: 3px;
    }
    .fight-table {
        width: calc(100vw - 3rem);
        display: flex;
        justify-content: center;
    }
}

/* Small (smaller than 640px)
Medium (641px to 1007px)
Large (1008px and larger) */